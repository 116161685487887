var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.loaded
    ? _c("div", { staticClass: "select-jurisdiction" }, [
        _vm._m(0),
        _vm._m(1),
        _c(
          "div",
          { staticClass: "jurisdiction-selectors-container" },
          [
            _c("vue-multiselect", {
              staticClass: "united-states-dropdown",
              attrs: {
                placeholder: "-- select state --",
                label: "state_province_region",
                value: _vm.jurisdiction,
                options: _vm.jurisdictions,
                multiple: false,
                searchable: true,
              },
              on: {
                input: function ($event) {
                  return _vm.onSelectedJurisdictionDropdown($event)
                },
              },
            }),
            _c(
              "ol",
              { attrs: { id: "united-states-map" } },
              _vm._l(99, function (index) {
                return _c(
                  "li",
                  {
                    key: index,
                    class: _vm.setStyleForState(index),
                    attrs: { title: _vm.findStateNameByMapId(index) },
                    on: {
                      click: function ($event) {
                        return _vm.onSelectedJurisdictionMap(index)
                      },
                    },
                  },
                  [
                    index in _vm.unitedStatesMapped
                      ? _c("p", { staticClass: "state-abbreviation" }, [
                          _vm._v(
                            "\n          " +
                              _vm._s(
                                _vm.unitedStatesMapped[index].abbreviation
                              ) +
                              "\n        "
                          ),
                        ])
                      : _vm._e(),
                  ]
                )
              }),
              0
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "continue-button-container" },
          [
            _vm.noJurisdictionSelected
              ? _c("span", { staticClass: "button-help-text" }, [
                  _vm._v(
                    "\n      Select your company home state to continue.\n    "
                  ),
                ])
              : _c("span", [
                  _vm._v(
                    "\n      Next, let's gather details about your business in " +
                      _vm._s(_vm.jurisdiction.state_province_region) +
                      "\n    "
                  ),
                ]),
            _c(
              "b-button",
              {
                staticClass: "continue-button",
                attrs: {
                  disabled: _vm.noJurisdictionSelected,
                  variant: "primary",
                  "aria-label": "continue button",
                },
                on: { click: _vm.next },
              },
              [_c("span", [_vm._v("\n        Continue\n      ")])]
            ),
          ],
          1
        ),
      ])
    : _c(
        "div",
        { staticClass: "center-spinner" },
        [_c("ct-centered-spinner")],
        1
      )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "title-container col-md-12 mb-3" }, [
      _c("h1", { staticClass: "title" }, [
        _vm._v(
          "\n      Where will (or did) you file your company paperwork?\n    "
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "description-container col-md-12" }, [
      _c("p", { staticClass: "description-text" }, [
        _vm._v(
          "\n      Most business owners choose the state where they first conduct business or hold assets.\n    "
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }